/* latin */
@font-face {
    font-family: 'Teko';
    font-style: normal;
    font-weight: 400;
    src: local('Teko Regular'), local('Teko-Regular'), url(fonts/Teko-Regular.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
  /* latin */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: local('Roboto'), local('Roboto-Regular'), url(fonts/Roboto-Regular.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 400;
    src: local('Roboto Condensed'), local('RobotoCondensed-Regular'), url(fonts/RobotoCondensed-Regular.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
  
:root {

    --gryfo-dark-blue: #24325C;
    --gryfo-blue: #2F52A0;
    --gryfo-dark-grey: #242433;
    --gryfo-grey: #525663;
    --gryfo-light-grey: #7F8794;
    --gryfo-light: #E4E8EB;
    --gryfo-yellow: #ffc107;
    --gryfo-dark-yellow: #F6A335;
    --gryfo-green: #84BD42;
    
}


body{
    background: #d8d8d8;
    background: url('images/shattered.png') repeat;
    font-family: 'Roboto', 'sans-serif';
}

*{
    box-sizing: border-box;
}

body{
    min-height: 100vh;
}

.text-gryfo-dark-blue{
    color: var(--gryfo-dark-blue);
}
.text-gryfo-blue{
    color: var(--gryfo-blue);
}
.text-gryfo-dark-grey{
    color: var(--gryfo-dark-grey);
}
.text-gryfo-grey{
    color: var(--gryfo-grey);
}
.text-gryfo-light-grey{
    color: var(--gryfo-light-grey);
}
.text-gryfo-light{
    color: var(--gryfo-light);
}
.text-gryfo-dark-yellow{
    color: var(--gryfo-dark-yellow);
}
.text-gryfo-green{
    color: var(--gryfo-green);
}

.bg-gryfo-dark-blue{
    background-color: var(--gryfo-dark-blue);
}
.bg-gryfo-blue{
    background-color: var(--gryfo-blue);
}
.bg-gryfo-dark-grey{
    background-color: var(--gryfo-dark-grey);
}
.bg-gryfo-grey{
    background-color: var(--gryfo-grey);
}
.bg-gryfo-light-grey{
    background-color: var(--gryfo-light-grey);
}
.bg-gryfo-light{
    background-color: var(--gryfo-light);
}
.bg-gryfo-dark-yellow{
    background-color: var(--gryfo-dark-yellow);
}
.bg-gryfo-green{
    background-color: var(--gryfo-green);
}
.bg-gryfo-yellow{
    background-color: var(--gryfo-yellow);
}

.btn-gryfo-blue{
    background-color: var(--gryfo-blue);
    border: solid 1px var(--gryfo-blue);
}

.btn-gryfo-blue:hover{
    background-color: var(--gryfo-dark-blue);
    border: solid 1px var(--gryfo-dark-blue);
}

.btn-gryfo-blue:focus, .btn-gryfo-blue:active{
    background-color: var(--gryfo-blue);
    border: solid 1px var(--gryfo-blue);
    outline: 0;
    box-shadow: none;
}

.btn-gryfo-grey{
    background-color: var(--gryfo-grey);
    border: solid 1px var(--gryfo-grey);
}

.btn-gryfo-grey:hover{
    background-color: var(--gryfo-dark-grey);
    border: solid 1px var(--gryfo-dark-grey);
}

.btn-gryfo-grey:focus, .btn-gryfo-grey:active{
    background-color: var(--gryfo-grey);
    border: solid 1px var(--gryfo-grey);
    outline: 0;
    box-shadow: none;
}

.btn-gryfo-yellow{
    background-color: var(--gryfo-yellow);
    border: solid 1px var(--gryfo-yellow);
}

.btn-gryfo-yellow:hover{
    background-color: var(--gryfo-dark-yellow);
    border: solid 1px var(--gryfo-dark-yellow);
}

.btn-gryfo-yellow:focus, .btn-gryfo-yellow:active{
    background-color: var(--gryfo-yellow);
    border: solid 1px var(--gryfo-yellow);
    outline: 0;
    box-shadow: none;
}

.status{
    display: flex;
    align-items:center;
    font-size: large;
    margin: 10px 0;
}

.status span{
    padding: 1px 6px;
    flex-grow: 1;
    border-radius: 3px;
}

.status-success{
    background-color: var(--gryfo-green);
    color: #fff;
}

.status-ok{
    background-color: #699735;
    color: #fff;
}

.status-warning{
    background-color: var(--gryfo-dark-yellow);
    color: #fff;
}

.status-danger{
    background-color: #dc3545;
    color: #fff;
}

.status-updating{
    background-color: #3578dc;
    color: #fff;
}

.h-100-percentage{
    height: 100%;
}

.cursor-pointer{
    cursor: pointer !important;
}

.btn-width-small{
    min-width: 150px;
    max-width: 150px;
}

.max-width-225{
    max-width: 225px;
}

.main_container{
    height: 100vh;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.side-link{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 10px;
    width: 100%;
    height: 5vh;
    text-decoration: none;
    color: var(--gryfo-light);
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    background-color: transparent;
    text-transform: uppercase;
}
.side-link:hover{
    text-decoration: none;
    filter: brightness(0.9);
    color: var(--gryfo-light-grey);    
}
.nav-link-active{
    display:flex;
    justify-content: flex-start;
    padding-left: 10px;
    align-items: center;
    font-family: 'Roboto', 'sans-serif';
    color: var(--gryfo-light);
    border-left: 3px solid var(--gryfo-blue);
    background: rgba(0, 0, 0, 0.2);
    width: 100%;
    height: 5vh;
}

.side-link-active{
    color: var(--gryfo-dark-yellow);
 }

#sidebar_wrapper{
    margin-left: 0px !important;
    transition: all .4s ease 0s;
}

#links_wrapper{
    margin-right: -67px ;
    padding: 10px;
    display: grid;
    gap: 10px;
    grid-auto-rows: 50px;
    align-items: center;
    grid-template-columns:
    "a b"
    "a b";
    transition: all .4s ease 0s;
}

#links_wrapper i{
    grid-area: a;
    transition: all .4s ease 0s;
}
#links_wrapper span{
    grid-area: b;
    visibility:hidden;
    transition: all .1s ease 0s;
}


#sidebar_wrapper.active #links_wrapper{
    margin-right: 0 !important;
    transition: all .2s ease 0s;
}



#sidebar_wrapper.active #links_wrapper span{
    visibility: initial;
    transition: all .4s ease 0s;
    padding-left: 20px;
    padding-right: 4px;
}

.dark-side-link{
    display: flex;
    text-decoration: none;
    color: var(--gryfo-light);
    text-transform: uppercase;
}
.dark-side-link:hover{
    text-decoration: none;
    filter: brightness(0.8);
    color: var(--gryfo-light);   
}

.mobile-nav{
    width: 100%;
    height: 15vh;
    display:flex;
    justify-content: space-around;
    align-items: center;
}

mobile-nav-bars:hover{
    text-decoration: none;
    color: var(--gryfo-dark-blue);
}

.navbar-light .navbar-toggler {
    border-color: rgba(0,0,0,0);
}

.navbar-toggler:focus, .navbar-toggler:hover {
    text-decoration: none;
    outline: 0;
}

.input-height{
    height: calc(1.5em + .75rem + 2px);
}

.icon-flipped {
    transform: scaleX(-1);
    -moz-transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    -ms-transform: scaleX(-1);
}

/*AUTH*/

.auth_container{
    margin: 0 auto;
    align-self: center;
    padding: 20px;
    border-radius: 3px;
    background-color: var(--gryfo-dark-grey);
    color: var(--gryfo-light);
    -webkit-box-shadow: 0px 0px 17px -3px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 17px -3px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 17px -3px rgba(0,0,0,0.75);
}

/* LOADING TEMPLATE */

.overlay {
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0, 0.9);
    overflow-x: hidden;
    overflow-y: hidden;
}

.overlay_content {
    color: #fff;
    position: relative;
    top: 40%;
    width: 100%;
    text-align: center;
    margin-top: 30px;
}

.overlay_content i {
    font-size: 20px;
}

/* ERROR */

.error_row{
    background-color: var(--gryfo-light);
    border-radius: 5px;
    -webkit-box-shadow: 2px 2px 8px -1px rgba(0,0,0,0.75);
    -moz-box-shadow: 2px 2px 8px -1px rgba(0,0,0,0.75);
    box-shadow: 2px 2px 8px -1px rgba(0,0,0,0.75);
}

.robot_error_image{
    max-width: 300px;
    width: 80%;
}

/* MODAL CAMERA */

.modal-full-screen{
    min-width: 100% !important;
    max-width: 100% !important;
    height: 100%;
    padding: 0 !important;
    margin: 0 !important;
    display: flex;
    overflow: hidden;
    background-color: #000;
}

.modal-full-screen .modal-content{
    background-color: #000 !important;
    display: flex;
    justify-content: center;
    align-content: center;
}

.modal-full-screen .modal-video-header{
    position: absolute;
    border: transparent;
    overflow: hidden;
    top: 0;
    right: 0;
    z-index: 101;
}

.modal-full-screen .web_camera{
    align-self: center;
    justify-self: center;
    background-color: #000;
    border: transparent;
    overflow: hidden;
    padding: 0;
    margin: 0;
}

.modal-full-screen .web_camera_preview{
    width: 50vh;
    align-self: center;
    justify-self: center;
    border-radius: 15px;
}

.modal-full-screen .div_camera_preview{
    display: flex;
    flex-grow: 1;
    background-color: #000;
}

.detecting.modal-full-screen::after {
    content: '';
    position: absolute;
    width: 100vw;
    height: 60px;
    background-image: linear-gradient(to bottom,
      rgba(170, 184, 190, 0),
      rgba(170, 184, 190, .8));
    animation: detecting .9s linear infinite;
  }
  
@keyframes detecting {
    100% { transform: translate(0, 100vh); }
}

.modal-full-screen .modal-video-footer{
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 101;
}

/*PERSONS*/

.image_button{
    padding: 8px;
    margin-bottom: -33px;
    z-index: 1;
    border-radius: 5px;
    -webkit-box-shadow: 2px 2px 4px -1px rgba(0,0,0,0.75);
    -moz-box-shadow: 2px 2px 4px -1px rgba(0,0,0,0.75);
    box-shadow: 2px 2px 4px -1px rgba(0,0,0,0.75);
    cursor: pointer;
}

.image_thumbnail{
    border-radius: 5px;
    -webkit-box-shadow: 2px 2px 4px -1px rgba(0,0,0,0.75);
    -moz-box-shadow: 2px 2px 4px -1px rgba(0,0,0,0.75);
    box-shadow: 2px 2px 4px -1px rgba(0,0,0,0.75);
}

.image_error_text {
    width: 100%;
}

.flex-container{
    flex-wrap: wrap;
}

@media (max-width: 767px) { 
    .image_thumbnail{
        max-width: 70vw;
        object-fit: contain;
    }
    .image_error_text{
        max-width: 70vw;
        object-fit: contain;
    }
}

@media (min-width: 768px) { 
    .image_thumbnail{
        width: 240px;
        height: 240px;
        object-fit: contain;
    }
    .image_error_text{
        width: 240px;
        object-fit: contain;
    }

 }

.image_button:hover{
    filter: brightness(0.8)
}

/* AUDITION */

.audition-cards-container{
    /* display: flex; */
    align-items: center;
    justify-content: center;
}

.audition-card-box{
    margin: 0.5vh;
    width: 180px;
    height: 240px;
    cursor: pointer;
}

.audition-card-header{
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    color:white;
    font-weight: bold;
    font-size: 11pt;
}

.audition-card-body{
    justify-content: center;
    padding: 0.5vh 0 0.5vh 0;
    background-color: var(--gryfo-light);
}

.audition-card-image{
    max-height: 80%;
    width: 85%;
    object-fit: contain;
    display: block;
    margin: auto;
}

.audition-card-text{
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    font-size: 10pt;
}

/* DATATABLE */
table.dataTable tbody td {
    vertical-align: middle;
}

.dataTables_paginate{
    padding: 15px 15px 15px 0;
}

.dataTables_paginate a{
    margin-right: 25px;
    background-color: var(--gryfo-blue);
    border: solid 1px var(--gryfo-blue);
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    padding: 10px 15px;
}

/* DATAPICKER */
.react-datepicker-wrapper {
    width:100%
}

/* PAGINATOR */
.paginator .page-link {
    color: var(--gryfo-blue);
    outline: 0;
    box-shadow:none;
}

.paginator .page-item.active .page-link{
    background-color: var(--gryfo-blue);
    border-color: var(--gryfo-blue);
}

.paginator dl,ol, ul{
    margin-bottom:0;
}

/* width */
::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: var(--gryfo-light);
    padding: 0;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: var(--gryfo-blue);
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: var(--gryfo-grey);
  }

  .event_list_item:hover, .event_list_item.event_active{
    filter:brightness(0.7);
    transition: all .2s;
    border: solid 1px var(--gryfo-blue);
  }

  .border-none {
    border: none;
  }